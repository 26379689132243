<template>
  <b-container data-aos="fade-up">
    <h1 class="mt-5 mb-2 text-primary">Terms of Use</h1>

    <p>
      Thorney, Harby and Wigsley Volunteer Car Scheme is committed to the
      highest standards of quality of information and makes every effort to
      ensure that information is accurate.
    </p>
    <p>
      While every care has been taken in the compilation of this information,
      Thorney, Harby and Wigsley Volunteer Car Scheme give no representations,
      assurances, undertakings or warranties about the accuracy, correctness or
      fitness for purpose regarding the site.
    </p>
    <p>
      Thorney, Harby and Wigsley Volunteer Car Scheme will not be held
      responsible for any loss, damage or inconvenience caused as a result of
      inaccuracy or error within these pages.
    </p>
    <p>
      Thorney, Harby and Wigsley Volunteer Car Scheme accepts no responsibility
      whatsoever for any reliance placed on the contents of the site.
    </p>
    <p>
      Before relying on any matter discussed in this site, you should seek
      professional advice on any proposed action. If you think that there are
      any errors or out-of-date information please notify us.
    </p>
    <p>
      Thorney, Harby and Wigsley Volunteer Car Scheme is not responsible for the
      content of any external websites and does not endorse any product or
      organisation contained therein. The content on this site is owned by and
      is the copyright of Thorney, Harby and Wigsley Volunteer Car Scheme.
    </p>
    <p>
      You may make copies for your personal use only. You are not allowed to
      copy and distribute content in any way without the prior consent of
      Thorney, Harby and Wigsley Volunteer Car Scheme. Use of documents and
      related graphics is for informational and non-commercial purposes only. No
      documents or related graphics may be modified in any way.
    </p>
    <p>
      The information on this website is intended for your general information.
      It is a guide only, before relying on any matter discussed in this site,
      you should seek professional advice on any proposed action.
    </p>
    <p>
      Thorney, Harby and Wigsley Volunteer Car Scheme has used all reasonable
      endeavour to ensure accuracy and completeness of this website. However,
      Thorney, Harby and Wigsley Volunteer Car Scheme gives no representation,
      assurance undertaking or warranty about the accuracy, correctness or
      fitness for purpose regarding the site or any website referred to by them
      ('the third party'). Thorney, Harby and Wigsley Volunteer Car Scheme
      accepts no responsibility whatsoever for any reliance placed on the
      contents of the site.
    </p>
    <p>
      Thorney, Harby and Wigsley Volunteer Car Scheme does not approve or
      endorse the information in any third party site. Thorney, Harby and
      Wigsley Volunteer Car Scheme accepts no responsibility for, and therefore
      will have no liability in connection with any third party site.
    </p>
  </b-container>
</template>

<script>
export default {
  name: "TandC",
};
</script>
